<template>
  <div id="app">
    <random-user />
  </div>
</template>

<script>
import RandomUser from './components/RandomUser.vue'

export default {
  name: 'App',
  components: {
    RandomUser
  },
}
</script>

<style>

</style>
