<template>
  <div class="game" :class="{ 'game__hidden': isOverlayActive }">
    <div v-if="!conditionsAccepted" class="game__overlay" />
    <h1 class="game__heading">Bienvenido a Christmas Valls. ¿Quién eres?</h1>
    <button
      class="button game__button"
      v-if="!isMessageOpen && !conditionsAccepted"
      @click="openMessage"
    >
     HAZ CLICK PARA LEER UN IMPORTANTE MENSAJE
    </button>
    <p class="game__warning" v-if="isMessageOpen && !conditionsAccepted">
      ¡DEBES HACER <b>CLICK EN TU NOMBRE</b>, DE LO CONTRARIO TE QUEDAS SIN
      REGALO <b>SINVERGÜENZA!</b> <br />
      <button
        class="button button--primary game__button"
        @click="acceptConditions"
      >
        ACEPTO LAS CONDICIONES
      </button>
    </p>

    <form class="game__box">
      <div
        class="game__card"
        :class="{ 'game__card--rotate-center': !isOverlayActive }"
        v-for="user in availableUsers"
        :key="user"
        @click="getUserName(user)"
      >
        {{ user.toUpperCase() }}
      </div>
      <modal
        v-if="userName && isOpen"
        :has-played="hasPlayed"
        :message="userMessage"
        :user-name="userName"
        :result="result"
        :intents="intents"
        :info-sent="infoSent"
        :is-loading="isLoading"
        @click="getUserToGift"
        @close="hideDialog"
      />
    </form>
    <img class="game__tree" src="/tree.png" alt="Árbol de navidad">
  </div>
</template>

<script>
import config from '@/config.js'

import endpoints from '@/services/apiEndpoints'
import ApiService from '@/services/apiService.js'

import Modal from '@/components/Modal.vue'

export default {
  components: {
    Modal
  },
  data () {
    return {
      userName: null,
      result: null,
      isOpen: false,
      isOverlayActive: true,
      isMessageOpen: false,
      infoSent: false,
      conditionsAccepted: false,
      hasPlayed: false,
      userWarning: '¡¡AVISO!! HAZ CLICK EN TU NOMBRE O TE QUEDAS SIN REGALO SINVERGÜENZA',
      intents: 0,
      isLoading: false,
    }
  },
  computed: {
    availableUsers () {
      const compoundUsers = {
        marioJr: 'mario junior',
        marioSr: 'mario senior'
      }
      return config.users.map(user => compoundUsers[user] ?? user).sort()
    },
    userMessage () {
      return this.result !== null
        ? `¡Gracias por participar!`
        : '¡Haz click para obtener tu resultado!'
    }
  },
  methods: {
    getUserName (user) {
      this.userName = user
      this.isOpen = true
      this.isOverlayActive = true
    },
    async getUserToGift () {
      const compoundUsers = {
        'mario senior': 'marioSr',
        'mario junior': 'marioJr'
      }
      const userData = {
        userName: compoundUsers[this.userName] ?? this.userName,
      }
      try {
        this.isLoading = true
        const intents = localStorage.getItem('intents')
        if (intents > 1) return
        const { data } = await ApiService.post(endpoints.getUserToGift, userData)
        this.result = data.userToGift.toUpperCase()
        this.infoSent = true
        this.intents += 1
        localStorage.setItem('intents', this.intents)
        this.isLoading = false
      } catch (err) {
        console.log(err, 'error', err.response)
      }
    },
    hideDialog () {
      this.isOpen = false
      this.isOverlayActive = false
    },
    openMessage () {
      this.isMessageOpen = !this.isMessageOpen
      this.isOverlayActive = true
    },
    acceptConditions () {
      this.conditionsAccepted = true
      this.isOverlayActive = false
    }
  }
}
</script>

<style lang="scss">
.game {
  margin: 0;
  padding: 0;
  background: $secondary-color;
  width: 100vw;

  @include breakpoint(md) {
    height: 100vh;
  }

  &__hidden {
    height: 100vh;
    overflow: hidden;
  }

  &__heading {
    margin: 0;
    padding: $spacer;
    text-align: center;
    font-size: ms(4);
  }

  &__box {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    place-items: center;

    @include breakpoint(md) {
      grid-template-columns: repeat(5, 1fr);
    }
  }

  &__card {
    @include bottom-shadow();
    background: $white-color;
    border-radius: 50%;
    color: $primary-darker-color;
    cursor: pointer;
    padding: $spacer*3;
    align-self: center;
    margin: $spacer;
    text-align: center;
    border: 4px ridge $primary-darker-color;
    justify-content: center;
    box-sizing: border-box;
    font-weight: $font-weight-bold;
    transition: border .2s ease-in;
    align-items: center;
    display: flex;
    width: 8.5em;
    height: 8em;

    @include breakpoint(md) {
      width: 10.5em;
      height: 10em;
    }

    &:hover {
      border: 4px solid $light-blue-color;
      color: $light-blue-color;
    }

    &--rotate-center {
      -webkit-animation: rotate-center 0.6s ease-in-out both;
      animation: rotate-center 0.6s ease-in-out both;
    }
  }

  &__warning {
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0);
    background: $white-color;
    padding: $spacer*2;
    border: 3px solid $primary-color;
    border-radius: $small-radius;
    z-index: 2;
    height: 18em;
    width: 17em;
    line-height: $spacer*2;
    text-align: center;

    @include breakpoint(md) {
      height: 15em;
      width: 20em;
    }
  }

  &__button {
    z-index: 2;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, 0%);

    &--secondary {
      margin: $spacer*2 auto;
    }
  }

  &__overlay {
    height: 100vh;
    width: 100vw;
    filter: blur(20%);
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    background-color: rgba(255, 255, 255, 0.5);
    top: 0;
    left: 0;
    position: absolute;
  }

  &__tree {
    display: none;

    @include breakpoint(md) {
      display: block;
      position: absolute;
      bottom: 0;
      right: $spacer*5;
      z-index: 2;
      mix-blend-mode: multiply;
      height: 20em;
    }
  }

  @-webkit-keyframes rotate-center {
  0% {
    -webkit-transform: rotate(0);
            transform: rotate(0);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
@keyframes rotate-center {
  0% {
    -webkit-transform: rotate(0);
            transform: rotate(0);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

}
</style>
