<template>
    <div class="modal">
        <div class="modal__overlay" />
        <div class="modal__box">        
            <p v-if="!isLoading">¡Hola <b>{{ userName.toUpperCase() }}</b>!</p>
            <button v-if="!isLoading" class="button"  @click.prevent="getUserToGift()">{{ message }}</button>
            <button v-if="!isLoading" class="button button--secondary" @click.prevent="hideDialog()">Cerrar</button>
            <button v-else class="button">CARGANDO MAGIA...</button>

        </div>
        <div v-if="result || result && userIntents === 1" class="modal__box">
            <p>¡Enhorabuena! La persona que te ha tocado es <b>{{ result }}</b></p>
            <button class="button button--secondary" @click.prevent="hideDialog()">Cerrar</button>
        </div>
        <div v-if="userIntents >= 1 && infoSent" class="modal__box">
            <p>¡Eeeh piratilla no intentes volver a jugar!</p>
            <button class="button button--secondary" @click.prevent="hideDialog()">Cerrar</button>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        userName: {
            type: String,
            required: true,
        },
        result: {
            type: String,
        },
        message: {
            type: String,
            required: true,
        },
        infoSent: {
            type: Boolean
        },
        isLoading: {
            type: Boolean,
            default: false,
        },
    },
    data () {
        return {
            userIntents: localStorage?.getItem('intents'),
        }
    },
    methods: {
        getUserToGift() {
            this.$emit('click', this.userIntents)
        },
        hideDialog() {
            this.$emit('close')
        }
    }
}
</script>

<style lang="scss">
.modal {

    &__overlay {
        height: 100vh;
        width: 100vw;
        filter: blur(20%);
        -webkit-backdrop-filter: blur(10px);
        backdrop-filter: blur(10px);
        background-color: rgba(255, 255, 255, 0.5);
        top: 0;
        left: 0;
        position: absolute;
    }

    &__box {
        height: 20em;
        width: 20em;
        background: $white-color;
        text-align: center;
        position: absolute;
        z-index: 2;
        left: 50%;
        top: 0;
        transform: translate(-50%, 50%);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border-radius: $small-radius;
        border: 2px solid $light-blue-color;

        @include breakpoint(md) {
            width: 35em;
        }
    }
}
</style>