import axios from 'axios'

const ApiService = {

  init () {
    axios.defaults.baseURL = process.env.VUE_APP_API_URL
  },

  get (resource, params) {
    return axios.get(resource, { params })
  },

  post (resource, data) {
    return axios.post(resource, data)
  },

  put (resource, data) {
    return axios.put(resource, data)
  },

  patch (resource, data) {
    return axios.patch(resource, data)
  },

  delete (resource) {
    return axios.delete(resource)
  },

}

export default ApiService